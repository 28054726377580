import React from "react";
import Box from "@mui/material/Box";
import PortfolioCards from "../PortfolioCard";
const PortfolioGrid = () => {
    return (
        <Box id='PortfolioPage' sx={{ padding: 4 }}>
            <PortfolioCards />
        </Box>
    );
};

export default PortfolioGrid;
