import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#C19A6B", // Gold
        },
        secondary: {
            main: "#e6e8e1", // Elegant light tone
        },
        background: {
            default: "#121212",
            paper: "#1A1A1A",
        },
        text: {
            primary: "#ffffff",
            secondary: "#e6e8e1",
        },
        divider: "#e6e8e122", // Very soft divider line
    },
    typography: {
        fontFamily: '"Archivo","Helvitica","Arial",sans-serif',
        h1: {
            fontFamily: `"Clash Display", "Archivo", sans-serif`,
            fontWeight: 600,
            fontSize: "3rem",
        },
        h2: {
            fontFamily: `"Clash Display", "Archivo", sans-serif`,
            fontWeight: 600,
            fontSize: "2.5rem",
        },
        h3: {
            fontFamily: `"Clash Display", "Archivo", sans-serif`,
            fontWeight: 600,
        },
        body1: {
            fontFamily: `"Archivo", "Helvetica", "Arial", sans-serif`,
            fontWeight: 400,
        },
        body2: {
            fontFamily: `"Archivo", "Helvetica", "Arial", sans-serif`,
            fontWeight: 400,
            color: "#e6e8e1", // Great for lighter body text
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    background: "linear-gradient(135deg, #C19A6B, #1F1F1F)",
                    "&:hover": {
                        backgroundColor: "#e6e8e1",
                        color: "#121212",
                    },
                },
            },
        },
    },
});

export default theme;
