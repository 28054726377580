import { MotionBox, MotionButton } from "../../Utility/motionComponents";
import Button from "@mui/material/Button";
import InstagramIcon from "@mui/icons-material/Instagram";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InstagramFeed from "../InstagramFeed";

const ContactPage = () => {
    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            id={"ContactPage"}
        >
            <Stack spacing={4}>
                <Typography variant='h4' fontWeight={600}>
                    Let’s connect
                </Typography>
                <Typography variant='body1'>
                    Reach out or follow my work on Instagram
                </Typography>
                <Button
                    variant='outlined'
                    startIcon={<InstagramIcon />}
                    href='https://instagram.com/jahniseyephotography'
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{
                        color: "#e6e8e1",
                        borderColor: "#C19A6B",
                        "&:hover": {
                            backgroundColor: "#C19A6B",
                            color: "#121212",
                        },
                    }}
                >
                    @jahniseyephotography
                </Button>
            </Stack>
            <InstagramFeed />
        </Stack>
    );
};

export default ContactPage;
