import Iceland_0 from "./PortfolioImages/Iceland/Iceland-1.webp";
import Iceland_1 from "./PortfolioImages/Iceland/Iceland-12.webp";
import Iceland_2 from "./PortfolioImages/Iceland/Iceland-3.webp";
import Iceland_3 from "./PortfolioImages/Iceland/Iceland-37.webp";
import Iceland_4 from "./PortfolioImages/Iceland/Iceland-38.webp";
import Iceland_5 from "./PortfolioImages/Iceland/Iceland-41.webp";
import Iceland_6 from "./PortfolioImages/Iceland/Iceland-66.webp";
import Iceland_7 from "./PortfolioImages/Iceland/Iceland-7.webp";
import Iceland_8 from "./PortfolioImages/Iceland/Iceland-71.webp";
import Iceland_9 from "./PortfolioImages/Iceland/Iceland-72.webp";
import Iceland_10 from "./PortfolioImages/Iceland/Iceland-9.webp";
import LiamAndSteph_11 from "./PortfolioImages/LiamAndSteph/BnG-12.webp";
import LiamAndSteph_12 from "./PortfolioImages/LiamAndSteph/BnG-26.webp";
import LiamAndSteph_13 from "./PortfolioImages/LiamAndSteph/BnG-51.webp";
import LiamAndSteph_14 from "./PortfolioImages/LiamAndSteph/BnG-94.webp";
import LiamAndSteph_15 from "./PortfolioImages/LiamAndSteph/Ceremony-103.webp";
import LiamAndSteph_16 from "./PortfolioImages/LiamAndSteph/Ceremony-127.webp";
import LiamAndSteph_17 from "./PortfolioImages/LiamAndSteph/Ceremony-150.webp";
import LiamAndSteph_18 from "./PortfolioImages/LiamAndSteph/Couple-62.webp";
import LiamAndSteph_19 from "./PortfolioImages/LiamAndSteph/Get Ready With me-26.webp";
import LiamAndSteph_20 from "./PortfolioImages/LiamAndSteph/Reception-71.webp";
import NathanAndChristen_21 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-145.webp";
import NathanAndChristen_22 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-165.webp";
import NathanAndChristen_23 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-175.webp";
import NathanAndChristen_24 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-199.webp";
import NathanAndChristen_25 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-202.webp";
import NathanAndChristen_26 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-205.webp";
import NathanAndChristen_27 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-233.webp";
import NathanAndChristen_28 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-267.webp";
import NathanAndChristen_29 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-365.webp";
import NathanAndChristen_30 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-392.webp";
import NathanAndChristen_31 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-42.webp";
import NathanAndChristen_32 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-60.webp";
import NathanAndChristen_33 from "./PortfolioImages/NathanAndChristen/Nathan and Christen-89.webp";
import Portugal_34 from "./PortfolioImages/Portugal/alleyway.webp";
import Portugal_35 from "./PortfolioImages/Portugal/apartments.webp";
import Portugal_36 from "./PortfolioImages/Portugal/balcony.webp";
import Portugal_37 from "./PortfolioImages/Portugal/boat.webp";
import Portugal_38 from "./PortfolioImages/Portugal/Hotel.webp";
import Portugal_39 from "./PortfolioImages/Portugal/houses.webp";
import Portugal_40 from "./PortfolioImages/Portugal/houses2.webp";
import Portugal_41 from "./PortfolioImages/Portugal/houses3.webp";
import Portugal_42 from "./PortfolioImages/Portugal/houses4.webp";
import Portugal_43 from "./PortfolioImages/Portugal/houses5.webp";
import Portugal_44 from "./PortfolioImages/Portugal/jahni.webp";
import Portugal_45 from "./PortfolioImages/Portugal/jahni2.webp";
import Portugal_46 from "./PortfolioImages/Portugal/kenzie.webp";
import Portugal_47 from "./PortfolioImages/Portugal/kenzie2.webp";
import Portugal_48 from "./PortfolioImages/Portugal/kenzie3.webp";
import Portugal_49 from "./PortfolioImages/Portugal/kitty.webp";
import Portugal_50 from "./PortfolioImages/Portugal/rocks.webp";
import Portugal_51 from "./PortfolioImages/Portugal/trees.webp";
import Portugal_52 from "./PortfolioImages/Portugal/trees2.webp";
import Portugal_53 from "./PortfolioImages/Portugal/windows.webp";
import Portugal_54 from "./PortfolioImages/Portugal/windows2.webp";
import TheTurcotts_55 from "./PortfolioImages/TheTurcotts/Daddy Daughter-3.webp";
import TheTurcotts_56 from "./PortfolioImages/TheTurcotts/Daddy Daughter-5.webp";
import TheTurcotts_57 from "./PortfolioImages/TheTurcotts/First Dance-8.webp";
import TheTurcotts_58 from "./PortfolioImages/TheTurcotts/First Look-4.webp";
import TheTurcotts_59 from "./PortfolioImages/TheTurcotts/Getting Ready-46.webp";
import TheTurcotts_60 from "./PortfolioImages/TheTurcotts/Getting Ready-49.webp";
import TheTurcotts_61 from "./PortfolioImages/TheTurcotts/The Boys-7.webp";
import TheTurcotts_62 from "./PortfolioImages/TheTurcotts/The Boys-9.webp";
import TheTurcotts_63 from "./PortfolioImages/TheTurcotts/The Ceremony-79.webp";
import TheTurcotts_64 from "./PortfolioImages/TheTurcotts/The Girls-18.webp";
import TheTurcotts_65 from "./PortfolioImages/TheTurcotts/The Girls-3.webp";
import TheTurcotts_66 from "./PortfolioImages/TheTurcotts/The Turcottes (1).webp";
import TheTurcotts_67 from "./PortfolioImages/TheTurcotts/The Turcottes (23).webp";
import TheTurcotts_68 from "./PortfolioImages/TheTurcotts/Wedding Party-11.webp";

export const portfolioImages = [
    {
        category: "Iceland",
        images: [
            {
                src: Iceland_0,
                alt: "Iceland 1.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_1,
                alt: "Iceland 12.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_2,
                alt: "Iceland 3.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_3,
                alt: "Iceland 37.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_4,
                alt: "Iceland 38.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_5,
                alt: "Iceland 41.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_6,
                alt: "Iceland 66.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_7,
                alt: "Iceland 7.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_8,
                alt: "Iceland 71.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_9,
                alt: "Iceland 72.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
            {
                src: Iceland_10,
                alt: "Iceland 9.webp",
                title: "Iceland .webp",
                tags: ["Iceland"],
            },
        ],
    },
    {
        category: "Liam & Steph",
        images: [
            {
                src: LiamAndSteph_11,
                alt: "BnG 12.webp",
                title: "BnG .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_12,
                alt: "BnG 26.webp",
                title: "BnG .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_13,
                alt: "BnG 51.webp",
                title: "BnG .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_14,
                alt: "BnG 94.webp",
                title: "BnG .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_15,
                alt: "Ceremony 103.webp",
                title: "Ceremony .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_16,
                alt: "Ceremony 127.webp",
                title: "Ceremony .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_17,
                alt: "Ceremony 150.webp",
                title: "Ceremony .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_18,
                alt: "Couple 62.webp",
                title: "Couple .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_19,
                alt: "Get Ready With me 26.webp",
                title: "Get Ready With me .webp",
                tags: ["LiamAndSteph"],
            },
            {
                src: LiamAndSteph_20,
                alt: "Reception 71.webp",
                title: "Reception .webp",
                tags: ["LiamAndSteph"],
            },
        ],
    },
    {
        category: "Nathan & Christen",
        images: [
            {
                src: NathanAndChristen_21,
                alt: "Nathan and Christen 145.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_22,
                alt: "Nathan and Christen 165.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_23,
                alt: "Nathan and Christen 175.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_24,
                alt: "Nathan and Christen 199.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_25,
                alt: "Nathan and Christen 202.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_26,
                alt: "Nathan and Christen 205.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_27,
                alt: "Nathan and Christen 233.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_28,
                alt: "Nathan and Christen 267.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_29,
                alt: "Nathan and Christen 365.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_30,
                alt: "Nathan and Christen 392.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_31,
                alt: "Nathan and Christen 42.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_32,
                alt: "Nathan and Christen 60.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
            {
                src: NathanAndChristen_33,
                alt: "Nathan and Christen 89.webp",
                title: "Nathan and Christen .webp",
                tags: ["NathanAndChristen"],
            },
        ],
    },
    {
        category: "Portugal",
        images: [
            {
                src: Portugal_34,
                alt: "alleyway.webp",
                title: "alleyway.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_35,
                alt: "apartments.webp",
                title: "apartments.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_36,
                alt: "balcony.webp",
                title: "balcony.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_37,
                alt: "boat.webp",
                title: "boat.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_38,
                alt: "Hotel.webp",
                title: "Hotel.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_39,
                alt: "houses.webp",
                title: "houses.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_40,
                alt: "houses2.webp",
                title: "houses.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_41,
                alt: "houses3.webp",
                title: "houses.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_42,
                alt: "houses4.webp",
                title: "houses.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_43,
                alt: "houses5.webp",
                title: "houses.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_44,
                alt: "jahni.webp",
                title: "jahni.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_45,
                alt: "jahni2.webp",
                title: "jahni.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_46,
                alt: "kenzie.webp",
                title: "kenzie.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_47,
                alt: "kenzie2.webp",
                title: "kenzie.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_48,
                alt: "kenzie3.webp",
                title: "kenzie.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_49,
                alt: "kitty.webp",
                title: "kitty.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_50,
                alt: "rocks.webp",
                title: "rocks.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_51,
                alt: "trees.webp",
                title: "trees.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_52,
                alt: "trees2.webp",
                title: "trees.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_53,
                alt: "windows.webp",
                title: "windows.webp",
                tags: ["Portugal"],
            },
            {
                src: Portugal_54,
                alt: "windows2.webp",
                title: "windows.webp",
                tags: ["Portugal"],
            },
        ],
    },
    {
        category: "Lindsey & Jamie",
        images: [
            {
                src: TheTurcotts_55,
                alt: "Daddy Daughter 3.webp",
                title: "Daddy Daughter .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_56,
                alt: "Daddy Daughter 5.webp",
                title: "Daddy Daughter .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_57,
                alt: "First Dance 8.webp",
                title: "First Dance .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_58,
                alt: "First Look 4.webp",
                title: "First Look .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_59,
                alt: "Getting Ready 46.webp",
                title: "Getting Ready .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_60,
                alt: "Getting Ready 49.webp",
                title: "Getting Ready .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_61,
                alt: "The Boys 7.webp",
                title: "The Boys .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_62,
                alt: "The Boys 9.webp",
                title: "The Boys .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_63,
                alt: "The Ceremony 79.webp",
                title: "The Ceremony .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_64,
                alt: "The Girls 18.webp",
                title: "The Girls .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_65,
                alt: "The Girls 3.webp",
                title: "The Girls .webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_66,
                alt: "The Turcottes (1).webp",
                title: "The Turcottes ().webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_67,
                alt: "The Turcottes (23).webp",
                title: "The Turcottes ().webp",
                tags: ["TheTurcotts"],
            },
            {
                src: TheTurcotts_68,
                alt: "Wedding Party 11.webp",
                title: "Wedding Party .webp",
                tags: ["TheTurcotts"],
            },
        ],
    },
];
