import { IconButton, Tooltip } from "@mui/material";
import { Home, PhotoCamera, ContactMail } from "@mui/icons-material";
import { MotionBox } from "../Utility/motionComponents";
const BubbleNav = () => {
    const navItems = [
        { icon: <Home />, label: "About", anchor: "AboutPage" },
        { icon: <PhotoCamera />, label: "Portfolio", anchor: "PortfolioPage" },
        { icon: <ContactMail />, label: "Contact", anchor: "ContactPage" },
    ];

    return (
        <MotionBox
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            sx={{
                position: "fixed",
                top: "50%",
                right: 16,
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                zIndex: 2000,
            }}
        >
            {navItems.map((item) => (
                <Tooltip
                    title={item.label}
                    placement='left'
                    arrow
                    key={item.label}
                >
                    <IconButton
                        onClick={()=>document
                            .getElementById(item.anchor)
                            ?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            })}
                        sx={{
                            backgroundColor: "#1a1a1a",
                            color: "#e6e8e1",
                            width: 56,
                            height: 56,
                            "&:hover": {
                                backgroundColor: "#C19A6B",
                                color: "#121212",
                            },
                            border: "1px solid #C19A6B",
                        }}
                    >
                        {item.icon}
                    </IconButton>
                </Tooltip>
            ))}
        </MotionBox>
    );
};

export default BubbleNav;
