import { MotionButton } from "./motionComponents";
import Typography from "@mui/material/Typography";

const AnimatedButton = ({ text, nextPage, isAnimated }) => {
    return (
        <MotionButton
            whileHover={{ scale: 1.1 }}
            variant='contained'
            style={{
                backgroundImage: !isAnimated
                    ? "linear-gradient(135deg, rgba(193,154,107,1), rgba(193, 154, 107, 0.2), rgba(193,154,107,1))"
                    : "linear-gradient(135deg, rgba(193, 154, 107, 0.2), rgba(193,154,107,1), rgba(193,154,107,0.2))",
                backgroundSize: "400% 400%",
                backgroundPosition: "0% 0%",
            }}
            animate={
                isAnimated
                    ? {
                          backgroundPosition: ["0% 0%", "100% 100%", "0% 0%"],
                      }
                    : undefined
            }
            transition={{
                duration: 8,
                repeat: Infinity,
                repeatType: "mirror",
                ease: "easeInOut",
            }}
            sx={{
                color: "#121212",
                paddingX: 4,
                paddingY: 1.5,
                fontWeight: 600,
                borderRadius: "2rem",
                boxShadow: "0px 0px 0px 1px #C19A6B",
                height: "5em",
                width: "10em",
                "&:hover": {
                    boxShadow: "0px 0px 0px 1px #f1f1f1",
                    background: "linear-gradient(135deg, #C19A6B, #1F1F1F)",
                },
                "&:hover .MuiTypography-root": {
                    color: "#f1f1f1",
                },
            }}
            onClick={() => {
                document.getElementById(nextPage)?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }}
        >
            <Typography
                sx={{
                    fontSize: {
                        xs: "1rem",
                        md: "2rem",
                    },
                    fontWeight: 600,
                    textShadow: "0px 0px 3px #f1f1f1",
                }}
            >
                {text}
            </Typography>
        </MotionButton>
    );
};

export default AnimatedButton;
