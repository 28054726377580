import { jahniLogo } from "../../Assets/imageExports";
import AnimatedButton from "../../Utility/AnimatedButton";

import Stack from "@mui/material/Stack";
const LandingPage = () => {
    return (
        <Stack
            id='LandingPage'
            spacing={3}
            alignItems='center'
            justifyContent='flex-end'
            sx={{
                minHeight: "100vh",
                padding: 4,
                backgroundImage: `url(${jahniLogo})`,
                backgroundPosition: "center",
                backgroundSize: "90%",
                backgroundRepeat: "no-repeat",
            }}
        >
            <AnimatedButton text={"Enter"} nextPage="AboutPage" isAnimated={true} />
        </Stack>
    );
};

export default LandingPage;
