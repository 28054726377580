import { useEffect } from "react";
import Box from "@mui/material/Box"
import Typography  from "@mui/material/Typography";
const InstagramFeed = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.lightwidget.com/widgets/lightwidget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
    return (
        <Box sx={{ p: 4 }}>
            <Typography variant='h5' mb={2} fontWeight={500}>
                Latest on Instagram
            </Typography>
            <Box
                sx={{
                    width: "90vw",
                    borderRadius: 2,
                    aspectRatio: "1.5",
                    overflow: "hidden",
                    height: {
                        xs: "90vh",
                        sm: "90vh",
                        md: "60vh",
                    },
                    "&::-webkit-scrollbar": {
                        display: "none"
                    }
                }}
            >
                <iframe
                    src='https://cdn.lightwidget.com/widgets/e34d1a2ad6de53969c49f503a7ae1437.html'
                    allowTransparency='true'
                    className='lightwidget-widget'
                    style={{
                        width: "90%",
                        height: "100%",
                        border: 0,
                        overflow: "hidden",
                        minHeight: "500px",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",

                    }}
                    title='Instagram Feed'
                />
            </Box>
        </Box>
    );
};

export default InstagramFeed
