import { landingPageImage } from "../../Assets/imageExports";
import { MotionBox } from "../../Utility/motionComponents";
import AnimatedButton from "../../Utility/AnimatedButton";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import { motion } from "framer-motion";

const text =
    `Hey, I’m Jahni—a Toronto-based photographer focused on travel, lifestyle, and wedding photography. I’m drawn to real moments and honest emotion, aiming to capture images that feel both timeless and personal. Whether I’m documenting a celebration, a quiet landscape, or a spontaneous adventure, I approach each story with intention, creativity, and a love for natural light.

This portfolio is a reflection of the people and places that inspire me. If you connect with my work, I’d love to hear from you. When I’m not shooting, you’ll probably find me chasing golden hour, exploring new cities, or posted up at a café with my camera close by.`.split(
        ""
    );

const AboutPage = () => {
    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            spacing={10}
            sx={{ minHeight: "100vh", padding: 4 }}
        >
            <MotionBox
                id='AboutPage'
                component={"img"}
                src={landingPageImage}
                sx={{
                    width: {
                        xs: "20rem",
                        md: "50rem",
                    },
                    minHeight: "auto",
                    borderRadius: "60%",
                }}
            />
            <MotionBox>
                <Paper
                    variant='outlined'
                    sx={{
                        width: {
                            xs: "25rem",
                            sm: "30rem",
                            md: "79.5rem",
                        },
                        height: "auto",
                        borderRadius: "2rem",
                        p: {
                            xs: "1rem",
                            sm: "2rem",
                        },
                    }}
                >
                    <Typography
                        flexWrap={"wrap"}
                        sx={{
                            fontFamily: {
                                xs: `"Archivo", "Helvetica", "Arial", sans-serif"`,
                                md: `"Clash Display", "Archivo", sans-serif`,
                            },
                            fontSize: {
                                xs: "1rem",
                                md: "2rem",
                            },
                            fontWeight: {
                                xs: 400,
                                md: 600,
                            },
                        }}
                    >
                        {text.map((word, i) => (
                            <motion.span
                                whileInView={{ opacity: 1 }}
                                viewport={{ once: true, amount: 0.75 }}
                                component='span'
                                key={i}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    duration: 0.25,
                                    delay: 0.0088,
                                }}
                            >
                                {word}
                            </motion.span>
                        ))}
                    </Typography>
                </Paper>
            </MotionBox>
        </Stack>
    );
};

export default AboutPage;
