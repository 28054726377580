// PortfolioCards.jsx
import React, { useState } from "react";
import {
    Box,
    Typography,
    CardMedia,
    CardActionArea,
    Modal,
    ImageList,
    ImageListItem,
    IconButton, useMediaQuery,useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { portfolioImages } from "../Assets/portfolioImageData";
import { MotionBox, MotionCard } from "../Utility/motionComponents";
import Stack from "@mui/material/Stack";

const PortfolioCards = () => {
    const [openModal, setOpenModal] = useState(null); // category name or null
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [lightboxImages, setLightboxImages] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md")); // matches xs and sm

    const handleCardClick = (category) => setOpenModal(category);
    const handleCloseModal = () => setOpenModal(null);

    return (
        <Stack direction={{ lg: "row", xs: "column" }} gap={2} mb={4}>
            {portfolioImages.map((section) => (
                <MotionCard
                    whileHover={{ scale: 1.02 }}
                    key={section.category}
                    sx={{
                        width: { xs: "100%", sm: "45%", md: "30%", lg: "20%" },
                        height: "auto",
                        borderRadius: 2,
                        overflow: "hidden",
                        boxShadow: 4,
                        "&:hover .MotionCard": {
                            background: "transparent",
                        },
                    }}
                    transition={{
                        duration: 0.3,
                        repeat: "none",
                        ease: "easeInOut",
                    }}
                >
                    <CardActionArea
                        onClick={() => handleCardClick(section.category)}
                    >
                        <CardMedia
                            component='img'
                            image={section.images[0].src.replace(
                                "/PortfolioImages/",
                                `/PortfolioImages/${section.category}/thumbnails/`
                            )}
                            alt={`${section.category} Cover`}
                            sx={{
                                aspectRatio: { sm: "3/2", lg: "4/2" },
                                objectFit: "cover",
                                width: "100%",
                            }}
                        />
                        <MotionBox
                            sx={{
                                padding: 2,
                                background:
                                    "linear-gradient(135deg, rgba(17, 16, 15, 0.8), rgba(193, 154, 107, 0.29), rgba(17, 16, 15, 0.8))",
                            }}
                            className='MotionBox'
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    color: "#e6e8e1",
                                    fontWeight: 200,
                                }}
                            >
                                {section.category}
                            </Typography>
                        </MotionBox>
                    </CardActionArea>

                    {/* Modal for this section */}
                    <Modal
                        open={openModal === section.category}
                        onClose={handleCloseModal}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                width: "95vw",
                                height: "100vh",
                                backgroundColor: "#1a1a1a",
                                padding: 2,
                                borderRadius: 2,
                                overflowY: "auto",
                                position: "relative",
                            }}
                        >
                            <IconButton
                                onClick={handleCloseModal}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    color: "#e6e8e1",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography
                                variant='h5'
                                sx={{
                                    color: "#e6e8e1",
                                    marginBottom: 2,
                                    fontWeight: 500,
                                }}
                            >
                                {section.category} Gallery
                            </Typography>
                            {openModal === section.category && (
                                <ImageList variant='masonry' cols={3} gap={12}>
                                    {section.images.map((img, index) => (
                                        <ImageListItem key={index}>
                                            <img
                                                src={img.src.replace(
                                                    "/PortfolioImages/",
                                                    `/PortfolioImages/${section.category}/thumbnails/`
                                                )}
                                                alt={img.alt}
                                                loading='lazy'
                                                style={{
                                                    borderRadius: "12px",
                                                    cursor: "pointer",
                                                    touchAction: "manipulation",
                                                    width: "100%",
                                                }}
                                                onClick={() => {
                                                    if (isMobile) {
                                                        handleCloseModal();
                                                        setTimeout(() => {
                                                            setLightboxImages(
                                                                section.images.map(
                                                                    (img) => ({
                                                                        src: img.src,
                                                                    })
                                                                )
                                                            );
                                                            setLightboxIndex(
                                                                index
                                                            );
                                                            setLightboxOpen(
                                                                true
                                                            );
                                                        }, 250);
                                                    } else {
                                                        setLightboxImages(
                                                            section.images.map(
                                                                (img) => ({
                                                                    src: img.src,
                                                                })
                                                            )
                                                        );
                                                        setLightboxIndex(index);
                                                        setLightboxOpen(true);
                                                    }
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            )}
                        </Box>
                    </Modal>
                    {/* Lightbox */}
                </MotionCard>
            ))}
            {lightboxOpen && (
                <Lightbox
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    index={lightboxIndex}
                    slides={lightboxImages}
                />
            )}
        </Stack>
    );
};

export default PortfolioCards;
