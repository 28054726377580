import "./App.css";
import LandingPage from "./Components/Overviews/LandingPage";
import AboutPage from "./Components/Overviews/AboutPage";
import PortfolioPage from "./Components/Overviews/PortfolioPage";
import ContactPage from "./Components/Overviews/ContactPage";
import BubbleNav from "./Components/BubbleNav";

import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BubbleNav/>
            <div className='App'>
                <LandingPage />
                <AboutPage />
                <PortfolioPage />
                <ContactPage />
            </div>
        </ThemeProvider>
    );
}

export default App;
